.icon-box {
  display: inline-block;
  padding: 0.375em;
  border-radius: 0.375em;
  line-height: 0;
  background-color: #E8F5FD;
}

.filter-icon {
  display: inline-block;
  background-color: #043649;
  mask-size: cover;
  width: 0.75em;
  height: 0.75em;
}

.filter-membership-segment {
  mask: url(/assets/icon/custom-automations/filters/icon-membership-segments.svg) no-repeat round;
}

.filter-purchase-volume {
  mask: url(/assets/icon/custom-automations/filters/icon_purchase-volume.svg) no-repeat round;
}

.filter-transaction-amount {
  mask: url(/assets/icon/custom-automations/filters/icon_transaction-amount.svg) no-repeat round;
}

.filter-visit-days {
  mask: url(/assets/icon/custom-automations/filters/icon_visit-days.svg) no-repeat round;
}
