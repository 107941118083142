$font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;

$gray-darker: #2A2D33 !default;
$gray-dark: #373a3c !default;
$gray: #55595c !default;
$gray-light: #818a91 !default;
$gray-lighter: #e7e7e7 !default;
$gray-lightest: #F5F5F5 !default;

$gray-100: #FAFAFB;
$gray-150: #F1F1F3;
$gray-200: #ECEDED;
$gray-300: #DEE1E3;
$gray-400: #ced4da;
$gray-450: #ADB5BD;
$gray-500: #9fa2a6;
$gray-550: #898989;
$gray-600: #70777d;
$gray-700: #494e52;
$gray-800: #393d40;
$gray-900: #252729;

$navbar-bg: #033649 !default;
$primary: #00A3E0 !default;
$primary-light: lighten($primary, 30%);
$secondary: $gray-lighter !default;
$success: #5cb85c !default;
$info: #5bc0de !default;
$warning: #f0ad4e !default;
$danger: #d9534f !default;
$inverse: $gray-dark !default;

$h1-font-size: 1.75rem !default;
$h2-font-size: 1.5rem !default;
$h3-font-size: 1.25rem !default;
$h4-font-size: 1.2rem !default;
$h5-font-size: 1.1rem !default;
$h6-font-size: 1rem !default;

$headings-line-height: 1.1 !default;

$input-placeholder-color: $gray-light !default;

$input-group-addon-bg: $gray-lighter !default;

$btn-padding-x: 1.2rem !default;
$btn-padding-y: .5rem !default;
$input-btn-padding-y-lg: .5rem !default;
$input-btn-padding-x-lg: 1.5rem !default;
$btn-line-height: 1.25rem !default;
$btn-font-weight: 600 !default;

$input-btn-font-family: sans-serif !default;

$btn-secondary-color: $gray-dark !default;
$btn-secondary-bg: $gray-lighter !default;
$btn-secondary-border: $gray-lighter !default;

$border-radius: 4px !default;
$input-border-radius: 4px !default;
$input-color-placeholder: #C8C8C8 !default;

$custom-file-height: 1.8rem !default;
$custom-file-width: 9rem !default;
$custom-file-focus-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem $primary !default;

$custom-file-line-height: 1.4 !default;
$custom-file-color: #fff !default;
$custom-file-bg: $primary !default;
$custom-file-border-width: 1px !default;
$custom-file-border-color: $primary !default;
$custom-file-border-radius: $border-radius !default;
$custom-file-box-shadow: inset 0 .2rem .4rem rgba(0, 0, 0, .05) !default;
$custom-file-button-color: $custom-file-color !default;
$custom-file-button-bg: #eee !default;

$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: $spacer,
        2: $spacer * 2,
        3: $spacer * 3,
        4: $spacer * 4,
        5: $spacer * 5,
) !default;
