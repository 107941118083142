/* You can add global styles to this file, and also import other style files */
@import "app/shared/styles/variables";
@import "bootstrap/scss/bootstrap";
@import "app/shared/styles/popover";
@import "app/shared/styles/customfilepicker";
@import "app/shared/styles/slider";
@import "ngx-toastr/toastr";

//$fa-font-path: "assets/fonts" !default;
@import "font-awesome/scss/font-awesome";
@import 'ngx-ui-switch/ui-switch.component';

@import "dragula/dist/dragula.css";

// font-weight: 300-light; 400-regular; 600-semibold; 700-bold; font-style: 400i-italic
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700');

@import "./trix.style";
@import "./custom-automation-filter-icons.style";

// Ubuntu Font
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('assets/fonts/ubuntu-v20-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/ubuntu-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ubuntu-700 - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('assets/fonts/ubuntu-v20-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/ubuntu-v20-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

[scrollShadow] {
  width: calc(100% + 15px);
  transition: box-shadow .3s ease-out;
  @media (min-width: 768px) {
    width: 100%;
    transition: none;
  }
}

.scroll-shadow {
  box-shadow: inset -35px 0px 20px -30px rgba(0,0,0,0.2);
  @media (min-width: 768px) {
    box-shadow: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.custom-slider .ngx-slider .ngx-slider-bar-wrapper {
  padding-top: 0;
  margin-top: 0;
  height: auto;
}

.custom-slider .ngx-slider .ngx-slider-bar {
  background: $gray-300;
  height: 10px;
}
.custom-slider .ngx-slider .ngx-slider-selection, .custom-slider .ngx-slider .ngx-slider-selection-bar {
  background: $primary;
}

.custom-slider .ngx-slider .ngx-slider-pointer {
  margin-top: 8px;
  cursor: grab;
  height: 20px;
  width: 20px;
  background-color: #FFF;
  box-shadow: 0 1px 10px 0 #AFAFAF;
  border-radius: 100%;
}

.custom-slider .ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.custom-slider .ngx-slider .ngx-slider-bubble {
  bottom: 14px;
}

.custom-slider .ngx-slider .ngx-slider-limit {
  font-weight: bold;
  color: orange;
}

.custom-slider .ngx-slider .ngx-slider-tick {
  width: 1px;
  height: 10px;
  margin-left: 4px;
  border-radius: 0;
  background: #ffe4d1;
  top: -1px;
}

.custom-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: orange;
}

.form-control {
  line-height: 1.25;
  padding: .5rem .75rem;
}

.form-row > .col {
  padding-right: unset;
  padding-left: unset;
}

h6, .h6 {
  margin-bottom: 1rem;
}

button {
  font-family: sans-serif;
}

h6, .h6 {
  font-weight: 500 !important;
}

.ngb-tp-input.form-control {
  width: 50px;
}

.ngb-tp-input-container {
  width: auto !important;
}

.btn-group-toggle > label {
  margin-right: .25rem;
}

.btn {
  white-space: nowrap;
}

html, body {
  height: 100%;
  color: $gray-dark;
}

app-root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.gray {
  color: $gray-light;
}

.gray-light {
  color: $gray-light;
}

label {
  margin-bottom: .125rem;
  font-weight: 600;

  span {
    color: $primary;
    font-weight: 500;
  }
}

strong {
  font-weight: 600;
}

.btn {
  font-size: 0.875rem;
  font-family: $font-family-sans-serif;
  cursor: pointer;
}

.btn-sm {
  font-size: 0.8125rem;
}

.btn-lg {
  font-size: 1.2rem;
}

fieldset.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  // Placeholder
  &::placeholder {
    font-weight: 300;
  }
}

.campaign-table {
  td {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    border-bottom: 1px solid $gray-lighter;

    h5, h6 {
      color: $gray-darker;
      margin-top: 4px;
      margin-bottom: 2px;
    }

    span {
      color: $gray-light;
      font-size: 0.75rem;
    }
  }
}

.card {
  border-radius: 6px;
  border: 2px solid rgba(0, 0, 0, 0.08);
}

.card-table {
  margin-bottom: 0;

  .time-range-picker {
    border: 2px solid #e5e5e5;
    padding: 10px 20px;
    margin: -12px 0px;
    border-radius: 5px;
    float: right;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  }

  .line-icon {
    width: 1.75em;
    margin-bottom: -15px;
    margin-top: -15px;
    margin-right: 10px;
  }

  &.table th, &.table td {
    vertical-align: middle;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &.table thead tr:nth-child(2) th {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  th {
    font-weight: 600;
  }

  th:not(:first-of-type), td:not(:first-of-type) {
    text-align: center;
  }
}

@media (max-width: 767.98px) {
  .campaign-table {
    tr {
      border-bottom: 1px solid $gray-lighter;

      &:first-of-type {
        border-top: 1px solid $gray-lighter;
      }
    }
    td {
      //display: block;
      //text-align: center;
      border: 0;
    }
  }
}


.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: $gray-lightest;
}

hr {
  border-top: 1px solid $gray-lighter;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.radio-row {
  display: flex;
  line-height: 1.3;
  margin-bottom: 1rem;

  input {
    margin-top: 0.25rem;
    margin-right: 0.25em;
  }

  input[type="radio"] {
    height: 0.875em;
  }

  label {
    display: block;
    float: right;
    width: 100%;

    span {
      font-weight: 500;
      color: $gray-light;
      font-size: 0.75rem;
    }
  }
}

h4 {
  margin-bottom: 1.3rem;
}

.two-titles-headcontent {
  margin-top: 3.5rem;
  margin-bottom: 0.5rem;
}

table.table {
  th {
    border-top: 0 none;
  }
}

headcontent.border + .table {
  td {
    border-top: 0 none;
  }
}


.nouislider-legend {
  margin-top: -0.5rem;
  font-size: 0.825rem;
  display: flex;
  padding: 0 0.25rem;
}

ngb-modal-window.centered {
  .modal-dialog {
    top: 50%;
    margin-top: -100px;
  }
}

.popover-header {
  padding: 8px 14px;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-right-radius: calc(0.3rem - 1px);
  border-top-left-radius: calc(0.3rem - 1px);
}

// this provides backwards compatibility for bootstrap v4
.visually-hidden {
  @include sr-only;
}

// fix incompatibility of ng-bootstrap with bootstrap v4
ngb-modal-backdrop.modal-backdrop.show {
  z-index: 1040 !important;
}
