@import "variables";

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; /* Fix 401 */
  cursor: pointer;
}

.noUi-connect {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-origin {
  position: absolute;
  height: 0;
  width: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 12px;
}

.noUi-horizontal .noUi-handle {
  width: 20px;
  height: 20px;
  left: -10px;
  top: -6px;
}

/* Styling;
 */
.noUi-target {
  border-radius: 4px;
  border: 1px solid #D3D3D3;
}

.noUi-connect {
  background: $primary;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

#duration_slider .noUi-connect {
  background: #F5F5F5;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}

.noUi-handle {
  // border: 1px solid #eeeeee;
  border-radius: 14px;
  background: #FFF;
  cursor: default;
  box-shadow: 0px 1px 10px 0px #AFAFAF;
}

.noUi-active {
  box-shadow: 0 3px 4px -1px #BBB;
}

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 9px;
  width: 1.5px;
  background: #D3D3D3;
  left: 7px;
  top: 6px;
}

.noUi-handle:after {
  left: 12px;
}

/* Disabled state;
 */

[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
