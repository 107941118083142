.popover {
  cursor: default;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15), 0px 0px 1px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  display: block;
  background-color: #fff;
  padding: 0;
  margin-top: 1rem;
  border: 1px solid #e6e6e6;

  .popover-content {
    padding: 0.5rem 0;
    margin: 0;

    .dropdown-item {
      padding: 0.3rem 1.5rem;
    }
  }
}

.popover.popover-bottom-right {
  animation-duration: 150ms;
  animation-name: slidein;

  .popover-arrow {
    top: -11px;
    right: 10px;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #e6e6e6;

    &:after {
      top: 1px;
      margin-left: -10px;
      content: "";
      border-top-width: 0;
      border-bottom-color: #fff;
    }
  }
}

@keyframes slidein {
  from {
    transform: translate(0, -10px);
    opacity: 0;
  }

  to {
    transform: translate(0, 0px);
    opacity: 1;
  }
}

ngb-popover-window {
  min-width: 200px;
  min-height: 100px;

  .popover-body {
    padding: 3px 10px;
  }

}
