@import "variables";

.custom-file {
  position: relative;
  display: inline-block;
  //width: $custom-file-width;
  height: $custom-file-height;
  cursor: pointer;
  text-align: center;

  .custom-file-input, uploader {
    min-width: $custom-file-width;
    margin: 0;
    filter: alpha(opacity=0);
    opacity: 0;

    &:focus ~ .custom-file-control {
      @include box-shadow($custom-file-focus-box-shadow);
    }

  }

  .custom-file-control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    line-height: $custom-file-line-height;
    color: $custom-file-color;
    user-select: none;
    background-color: $custom-file-bg;
    border: $custom-file-border-width solid $custom-file-border-color;
    @include border-radius($custom-file-border-radius);

    font-weight: 500;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
    text-align: center;

    &::before {
      position: absolute;
      top: -$custom-file-border-width;
      right: -$custom-file-border-width;
      bottom: -$custom-file-border-width;
      z-index: 6;
      display: block;
      padding: 0.25rem 0.5rem;
      line-height: $custom-file-line-height;
      color: $custom-file-button-color;
      background-color: $custom-file-button-bg;
      border: $custom-file-border-width solid $custom-file-border-color;
      @include border-radius(0 $custom-file-border-radius $custom-file-border-radius 0);

    }

  }
}
